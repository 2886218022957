exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account/[...].js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-activate-js": () => import("./../../../src/pages/activate.js" /* webpackChunkName: "component---src-pages-activate-js" */),
  "component---src-pages-business-week-getmaterials-js": () => import("./../../../src/pages/business-week/getmaterials.js" /* webpackChunkName: "component---src-pages-business-week-getmaterials-js" */),
  "component---src-pages-business-week-index-js": () => import("./../../../src/pages/business-week/index.js" /* webpackChunkName: "component---src-pages-business-week-index-js" */),
  "component---src-pages-business-week-questions-js": () => import("./../../../src/pages/business-week/questions.js" /* webpackChunkName: "component---src-pages-business-week-questions-js" */),
  "component---src-pages-business-week-register-js": () => import("./../../../src/pages/business-week/register.js" /* webpackChunkName: "component---src-pages-business-week-register-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-passwordrestore-js": () => import("./../../../src/pages/passwordrestore.js" /* webpackChunkName: "component---src-pages-passwordrestore-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */)
}

